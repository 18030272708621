import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

function NotFoundPage({
  data: {
    site: {
      siteMetadata: { author, title: siteTitle },
    },
  },
  location,
}) {
  return (
    <Layout author={author} location={location} title={siteTitle}>
      <h1>404 Not Found</h1>
    </Layout>
  )
}

export default NotFoundPage

export const Head = ({ location }) => <Seo location={location} title="404" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        author
        title
      }
    }
  }
`
